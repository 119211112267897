
//importing browserRouter routes and route
import { ToastContainer, Slide, toast } from "react-toastify";
import { BrowserRouter, Routes, Route,Navigate } from 'react-router-dom';
import JobDescription from './pages/humanresource-job-description';
import ResumeSelect from './pages/humanresource-resume-analysis';
import KnowLedge from './pages/knowledge-search';
import Plantlive from "./pages/plant-live";
import Analytics from './pages/analytics';
import Personal from './pages/personal-email-tools';
import AudioRecorder from './pages/ExampleComponent';
import DocQa from './pages/doc-qa';

//Finance
import FinanceExtractInvoice from './pages/finance-process-invoice';
import FinanceExtractEWayBill from './pages/finance-process-ewaybill';
import FinanceExtractSlip from './pages/finance-process-weighbridgeslip';

//Human Resource
import HumanResourceResumeFormatting from './pages/humanresource-resume-formatting';
import HRSalaryData from "./pages/humanresource-salary-data";
import HumanResourceResumeAnalysis from './pages/humanresource-resume-analysis';

//Marketing
import MarketingSocialMediaPost from './pages/marketing-socialmedia-post';
import MarketingBlog from './pages/marketing-blog';
import MarketingProductDescription from './pages/marketing-product-description';
import MarketingPresentation from './pages/marketing-presentation';

//Personal
import PersonalWriteEmail from './pages/personal-write-email';
//Login
import Login from './auth/Login';
import { RoutesProtected } from './auth/ProtectedRotes';
function App() {

  const isUserLoggedIn = !!localStorage.getItem("token");
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={3000} // Toast will auto-close after 5 seconds
      />
      <BrowserRouter>

      
        <Routes>
        <Route
            path="/"
            element={
              isUserLoggedIn ? (
                <Navigate to="/knowledge-search" replace />
              ) : (
                <Login />
              )
            }
          />
          <Route path="/" element={<Login />} />
          {/* <Route path="/knowledge-search" element={<KnowLedge />} /> */}
          <Route path="/audio" element={<AudioRecorder />} />
          <Route path="/doc-qa" element={<RoutesProtected cmp={DocQa} />}  />
          <Route path="/knowledge-search" element={<RoutesProtected cmp={KnowLedge} />}  />
          <Route path="/plant-live"  element={<RoutesProtected cmp={Plantlive} />} />
          <Route path="/humanresource-job-description"  element={<RoutesProtected cmp={JobDescription} />} />
          <Route path="/humanresource-salary-data"  element={<RoutesProtected cmp={HRSalaryData} />} />

          <Route path="/finance-process-invoice" element={<FinanceExtractInvoice />} />
          <Route path="/finance-process-ewaybill" element={<FinanceExtractEWayBill />} />
          <Route path="/finance-process-weighbridgeslip" element={<FinanceExtractSlip />} />

          <Route path="/humanresource-resume-formatting" element={<HumanResourceResumeFormatting />} />         
          <Route path="/humanresource-resume-analysis" element={<HumanResourceResumeAnalysis />} />

          <Route path='/marketing-socialmedia-post' element={<MarketingSocialMediaPost />} />
          <Route path='/marketing-blog' element={<MarketingBlog />} />
          <Route path='/marketing-product-description' element={<MarketingProductDescription />} />
          <Route path='/marketing-presentation' element={<MarketingPresentation />} />

          <Route path="/analytics" element={<Analytics />} />
          <Route path="/personal-write-email" element={<PersonalWriteEmail />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
